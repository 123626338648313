@use "~bootstrap/scss/bootstrap" as bs;
@use "sass:math";

html,
body,
#root {
  min-height: 100vh;
}

#root {
  display: grid;
  grid-template-rows: max-content 1fr max-content;
}

#site-header {
  padding-left: bs.$container-padding-x;
  padding-right: bs.$container-padding-x;
  margin-bottom: math.div(bs.$container-padding-x, 2);

  .user-avatar {
    border-radius: 50%;
    max-height: 2.5rem;
  }

  .navbar-brand {
    font-family: "Dancing Script", cursive;
    font-size: 1.5rem;
    padding: 0;
  }

  .navbar-brand svg {
    max-height: 2rem;
    max-width: 2rem;
    vertical-align: bottom;
    margin-bottom: 0.5rem;
  }

  i {
    vertical-align: bottom;
  }

  ul li {
    white-space: nowrap;
  }

  #site-navbar ul {
    flex-wrap: wrap;
  }
}

#site-footer {
  margin-top: math.div(bs.$container-padding-x, 2);
  border-top: bs.$border-width bs.$border-style bs.$border-color;

  display: flex;
  justify-content: center;
}

.sub-nav {
  margin-bottom: math.div(bs.$container-padding-x, 2);

  .nav-link {
    padding-top: 0;
    padding-bottom: 0;
  }
}

main {
  height: 100%;
  padding-left: bs.$container-padding-x;
  padding-right: bs.$container-padding-x;
}

@media only screen and (min-width: bs.breakpoint-min("lg")) {
  #site-header {
    display: grid;
    grid-template-columns: 1fr 1fr max-content max-content;
    grid-gap: 1rem;

    svg {
      max-height: 2.5rem;
    }

    .navbar-brand {
      font-size: 2rem;
    }
  }
}

.form-group {
  margin-bottom: 1em;
}

.flash-message-list {
  margin-top: 1em;
}

.form-actions {
  margin-top: 2rem;
  border-top: bs.$border-width bs.$border-style bs.$border-color;
  padding-top: 1rem;

  .btn + .btn {
    margin-left: 0.5rem;
  }
}

.spinner-expanded {
  display: flex;
  justify-content: center;
  align-items: center;
  // height: 100%;

  & > div {
    width: 20vw;
    height: 20vw;
  }
}

.action-column {
  text-align: right;
}

.no-data {
  font-style: italic;
}

tr.no-data {
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  text-align: center;
}

.action-column {
  white-space: nowrap;
}

.pagination {
  margin-top: 2rem;
  justify-content: center;
}

.recipes-action-list {
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: bs.$border-width bs.$border-style bs.$border-color;

  .btn {
    margin-right: 0.5rem;
  }

  .btn:last-child {
    margin-right: 0;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  a[href] {
    color: inherit;
  }
}

@media only screen and (min-width: bs.breakpoint-min("lg")) {
  main {
    width: bs.breakpoint-min("lg");
    margin-left: auto;
    margin-right: auto;
  }
}

.recipes-react-select.is-invalid > div {
  border-color: bs.$form-feedback-invalid-color;

  &:focus {
    border-color: bs.$form-feedback-invalid-color;
    box-shadow: 0 0 0 bs.$input-focus-width rgba(bs.$form-feedback-invalid-color, 0.25);
  }
}

.recipes-react-select.is-valid > div {
  border-color: bs.$form-feedback-valid-color;

  &:focus {
    border-color: bs.$form-feedback-valid-color;
    box-shadow: 0 0 0 bs.$input-focus-width rgba(bs.$form-feedback-valid-color, 0.25);
  }
}
