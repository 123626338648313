@use "~bootstrap/scss/bootstrap" as bs;
@use "sass:math";

.recipes-show-image {
  img {
    max-width: 100%;
    border-radius: 0.5rem;
  }
}

.recipes-show-portions {
  width: auto;
}

.recipes-show-step-model-ingredients-list {
  list-style-type: none;
  padding-left: 0;

  li {
    border-bottom: bs.$border-width bs.$border-style bs.$border-color;
    padding-bottom: bs.$spacer;
    margin-bottom: bs.$spacer;
  }

  li:last-of-type {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

.recipes-show-steps-list {
  list-style-type: none;
  padding-left: 0;

  & > li {
    border-bottom: bs.$border-width bs.$border-style bs.$border-color;
    padding-bottom: bs.$spacer;
    margin-bottom: bs.$spacer;
  }

  & > li:last-of-type {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

.recipes-recipe-show-steps-list-ingredients-list {
  margin-top: bs.$spacer;
  margin-bottom: bs.$spacer;

  small {
    display: block;
  }
}

.recipes-show-overview-ingredients-list {
  small {
    display: block;
  }
}

.recipes-recipes-show-tags-list {
  list-style-type: none;
  padding-left: 0;

  li {
    display: inline-block;
  }

  li + li {
    margin-left: math.div(bs.$spacer, 2);
  }
}

.recipes-show-calories-list {
  list-style-type: none;
  padding-left: 0;
  display: inline;

  li {
    display: inline;
  }

  li::after {
    content: ", ";
  }

  li:last-of-type::after {
    content: ".";
  }
}

.recipes-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.recipes-list-item {
  border-bottom: bs.$border-width bs.$border-style bs.$border-color;
  padding-bottom: bs.$spacer;
  margin-bottom: bs.$spacer;

  display: grid;
  grid-template-areas:
    "image image"
    "title title"
    "created calories"
    "tags tags"
    "action action";
  grid-template-columns: 1fr max-content;
  column-gap: bs.$spacer;

  h3 {
    grid-area: title;
  }

  .created {
    grid-area: created;
  }

  .recipes-recipes-show-tags-list {
    grid-area: tags;
    margin-bottom: 0.25rem;
  }

  .btn-group {
    grid-area: action;
  }

  .calories {
    grid-area: calories;
  }

  .recipe-preview {
    grid-area: image;
    border-radius: 0.5rem;

    &,
    & img {
      width: 100%;
    }

    img {
      border-radius: 0.5rem;
    }
  }
}

.recipes-list-item:last-of-type {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}

@media only screen and (min-width: bs.breakpoint-min("lg")) {
  .recipes-list-item {
    grid-template-columns: max-content 1fr max-content max-content;
    grid-template-rows: max-content max-content 1fr;
    gap: 0.5rem;
    grid-template-areas:
      "image title title calories"
      "image tags tags created"
      "image action action action";
    align-items: top;

    h3 {
      margin: 0;
      padding: 0;
    }

    .btn-group {
      justify-self: end;
      align-self: end;
    }

    .recipes-recipes-show-tags-list {
      font-size: 0.9em;
      margin-bottom: 0;
    }

    .recipe-preview {
      width: 150px;
    }
  }

  .recipes-show-image {
    float: right;
    max-width: 60%;
  }

  .recipes-show-step-model-ingredients-list {
    li {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: bs.$spacer;

      .btn {
        align-self: end;
        justify-self: start;
        margin: 0.25rem 0;
      }

      .form-group {
        margin-bottom: 0;
      }
    }
  }
}

.recipes-weekplan-searchbar {
  margin-bottom: 2 * bs.$spacer;
}
