@use "./variables";

@use "~bootstrap/scss/bootstrap";
@use "./fonts";

@use "./layout";
@use "./recipes";
@use "./ingredients";
@use "./tags";
@use "./weekplan";

@import "react-date-picker/dist/DatePicker.css";
@import "react-calendar/dist/Calendar.css";

.form-control.react-date-picker {
  .react-date-picker__wrapper {
    border: 0;
  }

  .react-date-picker__inputGroup {
    padding: 0;
  }

  .react-date-picker__button {
    padding-top: 0;
    padding-bottom: 0;
  }

  .react-date-picker__button:last-of-type {
    padding-right: 0;
  }
}

button.nav-link {
  padding: 0;
  border: 0;
  background: none;
}

.nav-button {
  margin-left: 0.5rem;
}
